<script>
import { mapGetters } from "vuex";

export default {
  props: {
    active: Boolean,
    value: {
      default: "",
      type: String,
    },
  },

  computed: {
    ...mapGetters("config", ["temp_trades"]),
  },

  methods: {
    keyupEvents(event) {
      /* ESC KEY */
      if (event.which == 27) {
        this.submit();
      }
    },
    submit(value = this.value) {
      this.$emit("input", value);
    },
  },
};
</script>

<template>
  <div class="finderMobileFilterView" :class="{ active }">
    <!-- <div class="app-bar filters__bar">
      <div class="btn--close">
        <b-button @click="submit()">
          <b-icon icon="times" />
        </b-button>
      </div>
      <h4 class="app-bar__title">Categoría de anuncios</h4>
    </div> -->
    <div class="filters__body">
      <span class="btn--close" type="is-light" @click="submit()">
        <b-icon icon="times" />
      </span>
      <br />
      <div class="content">
        <h1 class="title">Categorías <br />de anuncios</h1>
      </div>
      <div class="widget">
        <!-- <div></div> -->
        <div
          class="option"
          v-for="(data, index) in temp_trades"
          :key="index"
          :class="{ active: value == index }"
          @click="submit(index)"
        >
          <span class="sicon-ads"></span>
          <!-- <span>{{ data.label }}</span> -->
          <div class="cat">
            <template v-if="index == 'ltg_for_se'">
              <div class="cat__title">Venta</div>
              <div class="cat__subtitle">Sin muebles</div>
            </template>
            <template v-else-if="index == 'ltg_for_sf'">
              <div class="cat__title">Venta</div>
              <div class="cat__subtitle">Con muebles</div>
            </template>
            <template v-else-if="index == 'ltg_for_rde'">
              <div class="cat__title">Renta</div>
              <div class="cat__title">x Día</div>
              <div class="cat__subtitle">Sin muebles</div>
            </template>
            <template v-else-if="index == 'ltg_for_rdf'">
              <div class="cat__title">Renta</div>
              <div class="cat__title">x Día</div>
              <div class="cat__subtitle">Con muebles</div>
            </template>
            <template v-else-if="index == 'ltg_for_rme'">
              <div class="cat__title">Renta</div>
              <div class="cat__title">x Mes</div>
              <div class="cat__subtitle">Sin muebles</div>
            </template>
            <template v-else-if="index == 'ltg_for_rmf'">
              <div class="cat__title">Renta</div>
              <div class="cat__title">x Mes</div>
              <div class="cat__subtitle">Con muebles</div>
            </template>
          </div>
        </div>
        <!-- <div></div> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html[route="finder-filter-trade"],
html[route="finder-filter-trade"] body {
  background-color: #eaeaec;
}
</style>

<style lang="scss" scoped>
.filters__body {
  background-color: #eaeaec;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  padding: 1.5rem;
}
.widget {
  width: 100%;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  // height: 100%;
  align-items: center;
  margin-bottom: 0 !important;
  .option {
    position: relative;
    // display: flex;
    background-color: #fff;
    padding: 1.75rem 1.5rem;
    padding: 1.5rem 1.25rem;
    padding: 1.25rem 1.125rem;
    height: 100%;
    margin-bottom: 0;
    // background-color: $sitio1;
    // border: 2px solid $sitio2;
    // color: $sitio2;
    border-radius: 1rem;
    // text-align: center;
    height: 8rem;
    // height: 9rem;
    // height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-bottom-left-radius: 0;
    .icon-ads {
      position: absolute;
      top: -0.75rem;
      right: -0.75rem;
      font-size: 2.125rem;
      font-size: 2rem;
      font-size: 1.875rem;
      margin-bottom: 1rem;
      color: #fff;
      background-color: $sitio1;
      border: 6px solid #eaeaec;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
    }
    .cat__title {
      color: #000;
      font-size: 1.375rem;
      font-weight: bold;
      line-height: 1;
    }
    .cat__subtitle {
      display: inline-block;
      font-size: 0.75rem;
      font-size: 0.6875rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      opacity: 0.8;
      border-bottom: 1px solid $sitio1;
    }
  }
  .option:nth-child(odd) {
    .cat__subtitle {
      border-color: $sitio3;
    }
    .icon-ads {
      background-color: $sitio3;
    }
    &.active .icon-ads {
      border-color: $sitio3;
      background-color: $sitio3;
    }
  }
  .option.active {
    z-index: 1;
    color: #fff;
    background-color: $sitio2;
    background: linear-gradient(
      140deg,
      rgba(83, 96, 255, 1) 0%,
      rgba(62, 74, 223, 1) 100%
    );
    box-shadow: 1px 6px 12px 2px #00000052;
    transform: scale(1.05);
    .icon-ads {
      font-size: 2.5rem;
      font-size: 2.75rem;
      color: #fff;
      border-color: #00d6d5;
      background-color: #00d6d5;
    }
    .cat__title {
      color: #fff;
    }
  }
}
</style>
